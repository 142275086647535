import { Alert, AlertTitle, Slide, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react'

const Alerts = () => {

    const [alert,setAlert] = useState({
        open:false,
        type: 'success',
        vertical: 'bottom',
        horizontal: 'right',
    });

    function SlideTransition(props) {
        return <Slide {...props} direction="up" />;
    }

    useEffect(()=>{
        setTimeout(()=>{
            setAlert({
                ...alert,
                open: true,
                type: 'success',
                title: 'React & MUI',
                description: 'This is built with React & MUI',
            });
        },5000);
        setTimeout(()=>{
            setAlert({
                ...alert,
                open:true,
                type: 'info',
                vertical: 'top',
                horizontal: 'center',
                title: 'Experience',
                description: 'News Feed first four items are companies I worked for.',
            });
        },10000);
        setTimeout(()=>{
            setAlert({
                ...alert,
                open:true,
                type: 'warning',
                vertical: 'bottom',
                horizontal: 'center',
                title: 'Education',
                description: 'News Feed last two images shows my colleges I studied.',
            });
        },15000);
        setTimeout(()=>{
            setAlert({
                ...alert,
                open:true,
                type: 'error',
                vertical: 'top',
                horizontal: 'right',
                title: 'Porfolio',
                description: 'Middle images box shows projects, click on them for more info.',
            });
        },20000);
        setTimeout(()=>{
            setAlert({
                ...alert,
                open: true,
                type: 'success',
                vertical: 'top',
                horizontal: 'left',
                title: 'Resume',
                description: 'Click on the link to Download my Resume.',
            });
        },25000);
        setTimeout(()=>{
            setAlert({
                ...alert,
                open:true,
                type: 'info',
                vertical: 'bottom',
                horizontal: 'left',
                title: 'Contact',
                description: 'Click on + icon to contact me.',
            });
        },30000);
        setTimeout(()=>{
            setAlert({
                ...alert,
                open:true,
                type: 'warning',
                vertical: 'bottom',
                title: 'Dark Mode',
                description: 'Experience the properly implemented dark mode with simplest method.',
            });
        },35000);
        setTimeout(()=>{
            setAlert({
                ...alert,
                open:true,
                type: 'error',
                vertical: 'top',
                horizontal: 'left',
                title: 'Email',
                description: 'Please email me directly on madmak787@gmail.com.',
            });
        },40000);
        setTimeout(()=>{
            setAlert({
                ...alert,
                open:false,
                type: 'error',
                vertical: 'top',
                horizontal: 'left',
            });
        },45000);
    },[]);

    const {open, type, vertical, horizontal, title, description} = alert;
    return (
        <>
            <Snackbar
                open={open}
                TransitionComponent={SlideTransition}
                anchorOrigin={{
                    vertical,
                    horizontal
                }}
            >
                <Alert severity={type}>
                    <AlertTitle>{title}</AlertTitle>
                    {description}
                </Alert>
            </Snackbar>
        </>
    )
}

export default Alerts