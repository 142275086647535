import { ExpandMore, Favorite, FavoriteBorder, MoreVert, Share } from '@mui/icons-material'
import { Avatar, Card, CardActions, CardContent, CardHeader, CardMedia, Checkbox, Collapse, IconButton, Typography, styled } from '@mui/material'
import { useState } from 'react';

const ExpandMoreH = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const Post = ({ title, logo, image, designation, address, period, caption }) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card key={title} sx={{ marginBottom: "25px" }}>
            <CardHeader
                avatar={
                    <Avatar sx={{ width: 45, height: 45, border:"1px solid #1976d2" }} src={`/media/experiences${logo}`} />
                }
                action={
                    <IconButton aria-label={title}>
                        <MoreVert />
                    </IconButton>
                }
                title={title}
                subheader={period}
            />
            <CardMedia
                component="img"
                height="265px"
                image={`/media/experiences${image}`}
                alt={title}
                sx={{ objectPosition: "top" }}
            />
            <CardContent>
                <Typography variant="body2" color="text.secondary">
                    Worked as <strong>{designation}</strong> at {title}'s <strong>{address}</strong> office.
                </Typography>
            </CardContent>
            <CardActions disableSpacing>
                <IconButton aria-label="Favorite">
                    <Checkbox icon={<FavoriteBorder />} checkedIcon={<Favorite sx={{ color: "red" }} />} />
                </IconButton>
                <IconButton aria-label="Share">
                    <Share />
                </IconButton>
                <ExpandMoreH
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMore />
                </ExpandMoreH>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    {
                        caption.split(";").map((c) => (
                            <Typography paragraph>{c}</Typography>
                        ))
                    }

                </CardContent>
            </Collapse>
        </Card>
    )
}

export default Post