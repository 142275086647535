import { Box, Stack, Skeleton, Divider, Chip, ImageList } from '@mui/material'
import React, { useState } from 'react'
import Post from './Post'
import { experiences, education } from '../utilities/data'
import ImagePost from './ImagePost'

const Feed = () => {
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(true);

    setTimeout(() => {
        setLoading(false);
    }, [1000]);
    
    setTimeout(() => {
        setLoading2(false);
    }, [3000]);

    return (
        <Box flex={4} p={{ xs: 0, md: 2 }} sx={{ display: { xs: "block", sm: "block" },flex:{xs:"1",sm:"4"} }}>
            {loading ? (
                <Stack spacing={1}>
                <Skeleton variant="text" height={100} />
                <Skeleton variant="text" height={20} />
                <Skeleton variant="text" height={20} />
                <Skeleton variant="rectangular" height={300} />
                </Stack>
            ) : (
                <section id="experiences">
                {experiences.map((exp)=>(
                    <Post {...exp} />
                ))}
                </section>
            )}

            <Divider>
                <Chip label="Education" size="small" variant='outlined' sx={{color: "text.primary",margin:'25px auto 25px'}} />
            </Divider>

            {loading2 ? (
                <Stack spacing={1} direction={`row`}>
                    <Skeleton variant="rectangular" height={150} width={`50%`} />
                    <Skeleton variant="rectangular" height={150} width={`50%`} />
                </Stack>
            ) : (
                <section id="education">
                <ImageList>
                    {education.map((edu)=>(
                        <ImagePost {...edu} />
                    ))}
                </ImageList>
                </section>
            )}
        </Box>
    )
}

export default Feed