import { Avatar, AvatarGroup, Box, Divider, IconButton, ImageList, ImageListItem, ImageListItemBar, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import React, { useState } from 'react'
import { skills, projects } from '../utilities/data';
import { Link } from '@mui/icons-material';
import Video from './Video';

function srcset(image, width, height, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${width * cols}&h=${height * rows
            }&fit=crop&auto=format&dpr=2 2x`,
    };
}

const Rightbar = () => {
    const [open, setOpen] = useState(false);
    const [videosrc, setVideosrc] = useState('');
    const max = 7;
    const videos = ["mp4", "3gp", "ogg"]

    const playVideo = (src) => {
        const ext = src.split('.').pop();
        if (videos.includes(ext)) {
            setVideosrc(src);
            setOpen(true);
        } else {
            window.open(src, '_blank').focus();
        }
    }

    return (
        <>
            <Box flex={1.5} p={2} sx={{ display: { xs: "block", sm: "block" },flex:{xs:"1",sm:"1.5"} }}>
                <Box position={{xs:'relative',sm:'fixed'}}>
                    <Typography variant='h6' fontWeight={100}>Technical Skills</Typography>
                    <AvatarGroup max={max} sx={{ justifyContent: "center" }}>
                        {Object.keys(skills).map((s, i) => {
                            if (i > max - 1) return '';
                            return <Avatar sx={{ marginRight: '5px' }} alt={s} src={`/media/skills${skills[s]}`} />
                        })}
                    </AvatarGroup>
                    <AvatarGroup max={max} sx={{ justifyContent: "center" }}>
                        {Object.keys(skills).map((s, i) => {
                            if (i <= max - 1) return '';
                            return <Avatar sx={{ marginRight: '5px' }} alt={s} src={`/media/skills${skills[s]}`} />
                        })}
                    </AvatarGroup>

                    <Typography variant='h6' fontWeight={100} mt={2} mb={2}>Portfolio</Typography>
                    <ImageList
                        sx={{
                            height: 150
                        }}
                        rowHeight={50}
                        cols={2}
                        gap={3}
                    >
                        {projects.map((item) => {
                            const cols = item.featured ? 1 : 1;
                            const rows = item.featured ? 1 : 1;
                            return (
                                <ImageListItem sx={{
                                    height:150,
                                    width:150,
                                    marginBottom:5
                                }} key={item.image} cols={cols} rows={rows} onClick={e => playVideo(item.video)}>
                                    <img
                                        {...srcset(`/media/portfolio${item.image}`, 50, 50, rows, cols)}
                                        alt={item.name}
                                        loading="lazy"
                                    />
                                    <ImageListItemBar
                                        sx={{
                                            fontSize:8,
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            background:
                                                'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                                'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                        }}
                                        title={item.name}
                                        position="top"
                                        actionIcon={
                                            <IconButton
                                                sx={{ color: 'white' }}
                                                aria-label={`star ${item.name}`}
                                            >
                                                <Link />
                                            </IconButton>
                                        }
                                        actionPosition="left"
                                    />
                                </ImageListItem>
                            )
                        })}
                    </ImageList>
                    <Typography variant='h6' fontWeight={100} mt={3}>Other Achievements</Typography>
                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                                <Avatar alt="WPSPINS" src="/media/experiences/wpspins-logo.png" />
                            </ListItemAvatar>
                            <ListItemText
                                primary="Freelance WP Dev"
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            WpSpins.com
                                        </Typography>
                                        {" — Worked on many plugins and themes for Houston based company."}
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                                <Avatar alt="LLCT20" src="/media/experiences/llc-logo.png" />
                            </ListItemAvatar>
                            <ListItemText
                                primary="IT Consultant"
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            LLCT20.com
                                        </Typography>
                                        {" — Created Website, Player Draft App, Trophy Tour page etc."}
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                    </List>
                </Box>
            </Box>
            <Video open={open} setOpen={setOpen} videosrc={videosrc} />
        </>
    )
}

export default Rightbar