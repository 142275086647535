import {
    Alert,
    AlertTitle,
    Avatar,
    Button,
    ButtonGroup,
    Fab,
    InputBase,
    Modal,
    Snackbar,
    Stack,
    styled,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
    Add as AddIcon,
    DateRange,
    EmojiEmotions,
    Image,
    PersonAdd,
    VideoCameraBack,
} from "@mui/icons-material";
import { Box } from "@mui/system";

const SytledModal = styled(Modal)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});

const UserBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "10px",
    marginBottom: "20px",
});

const Add = ({ open, setOpen }) => {
    const [success,setSuccess] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        var frmData = new FormData(e.currentTarget);
        console.log('frmData:', frmData)
        var requestOptions = {
            method: 'POST',
            body: frmData,
            redirect: 'follow'
        };
        fetch("http://khanamir.me/api/contact/", requestOptions)
            .then(response => response.text())
            .then(result => setSuccess(true))
            .catch(error => setSuccess(true));
    }

    return (
        <>
            <Tooltip
                onClick={(e) => setOpen(true)}
                title="Contact Me"
                sx={{
                    position: "fixed",
                    bottom: 20,
                    left: { xs: "calc(50% - 25px)", md: 30 },
                }}
            >
                <Fab color="primary" aria-label="add">
                    <AddIcon />
                </Fab>
            </Tooltip>
            <SytledModal
                open={open}
                onClose={(e) => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    width={400}
                    height={280}
                    bgcolor={"background.default"}
                    color={"text.primary"}
                    p={3}
                    borderRadius={5}
                >
                    <Typography variant="h6" color="gray" textAlign="center">
                        Contact Me
                    </Typography>
                    <form action="#" method="post" onSubmit={handleSubmit}>
                        <UserBox>
                            <Avatar
                                src="/media/amir.jpg"
                                sx={{ width: 30, height: 30 }}
                            />
                            <InputBase placeholder='Your Name...' name="name" sx={{
                                input: {
                                    color: 'currentColor',
                                    "&::placeholder": {
                                        opacity: 0.9,
                                    },
                                }
                            }} />
                        </UserBox>
                        <TextField
                            sx={{ width: "100%" }}
                            id="standard-multiline-static"
                            multiline
                            rows={3}
                            placeholder="What's on your mind?"
                            variant="standard"
                            name="message"
                        />
                        <Stack direction="row" gap={1} mt={2} mb={3}>
                            <EmojiEmotions color="primary" />
                            <Image color="secondary" />
                            <VideoCameraBack color="success" />
                            <PersonAdd color="error" />
                        </Stack>
                        <ButtonGroup
                            fullWidth
                            variant="contained"
                            aria-label="outlined primary button group"
                        >
                            <Button type="submit">Send</Button>
                            <Button sx={{ width: "100px" }}>
                                <DateRange />
                            </Button>
                        </ButtonGroup>
                    </form>
                </Box>
            </SytledModal>
            <Snackbar
                open={success}
            >
                <Alert severity="success">
                    <AlertTitle>Success</AlertTitle>
                    Your message has been sent successfully.
                </Alert>
            </Snackbar>
        </>
    );
};

export default Add;