export const experiences = [
    {
        title: "Sivana ICC",
        logo: "/sivana-logo.png",
        image: "/sivana-bg.png",
        designation: "Lead Software Engineer",
        address: "Oman (Remote)",
        period: "Feb 2018-Present",
        caption: "Worked with cross-functional teams to ensure successful implementation of new technologies.;Orchestrated the implementation of new sales strategies, resulting in a 25% increase in average deal size and a 30% growth in revenue within three months.;Coordinated with clients to understand their requirements and provided solutions accordingly.;Conducted regular meetings with the team to ensure smooth functioning of the project."
    },
    {
        title: "Incedo Inc.",
        logo: "/incedo-logo.png",
        image: "/incedo-bg.jpg",
        designation: "Senior Software Engineer",
        address: "Gurgaon, India",
        period: "Jun 2017 - Feb 2018",
        caption: "Conducted in-depth interviews and surveys with global clients to gather feedback on product features and functionality, resulting in the implementation of key enhancements that led to a 20% increase in customer retention.;Worked on the development of an ecommerce project based on Magento for One Fight Championship.;Led the development of ecommerce projects based on Magento Community.;Ensured that the site was responsive and optimized for different devices."
    },
    {
        title: "Byte Matrix Pvt. Ltd.",
        logo: "/bytematrix-logo.png",
        image: "/bytematrix-bg.png",
        designation: "Senior Web Developer",
        address: "New Delhi, India",
        period: "Jul 2015 - Jun 2017",
        caption: "Worked on integrating payment gateway APIs like Stripe, Paypal, Authorize.net, HDFC payment Gateway, Axis Bank Payment Gateway, eWay, WorldPay, etc.;Worked on various open source technologies to execute client requirements.;Researched and learned new technologies to improve business operations.;Worked with cross-functional teams to ensure successful project delivery."
    },
    {
        title: "Rishti India",
        logo: "/rishti-logo.png",
        image: "/rishti-bg.jpg",
        designation: "Software Engineer",
        address: "Noida, India",
        period: "Feb 2013 - Jul 2015",
        caption: "Developed web applications using Core PHP and open source technologies like Magento, Wordpress, Joomla, Drupal, Opencart, Oscommerce, Prestashop.;Led a team of 8 people to deliver an important government project for local elections in Uttar Pradesh, working directly with the Director of Local Bodies.;Possess skills in software development, web technologies, and content management."
    }
];

export const education = [
    {
        title: "Rajarshi Rananjay Sinh Institute Of Management And Technology",
        logo: "/rrsimt-logo.jpeg",
        image: "/rrsimt-bg.jpeg",
        course: "B-Tech in Computer Science",
        university: "AKTU",
        period: "2011-2014"
    },
    {
        title: "Bakhshi Polytechnic",
        logo: "/bakhshi-logo.jpg",
        image: "/bakhshi-bg.jpg",
        course: "Diplomacy in Computer Science",
        university: "BTEUP",
        period: "2008-2011"
    },
];

export const skills = {
    NextJS: "/nextjs.png",
    ReactJS: "/react.svg",
    NodeJS: "/nodejs.png",
    ExpressJS: "/express-js.png",
    TypeScript: "/typescript.png",
    MongoDB: "/mongodb.png",
    MaterialUI: "/mui.png",
    ShardCN: "/shardcn.png",
    Figma: "/figma.webp",
    Redux: "/redux.png",
    AWS: "/aws.png",
    CodeIgniter: "/codeigniter.png",
    WordPress: "/wordpress.png",
};

export const projects = [
    {
        featured: true,
        name: "Holiday Homes Concepts",
        image: "/holidayhome.jpg",
        video: "https://www.youtube.com/embed/yWzsO7MwLP0?si=6T0ufcN6b_gQlf7x"
    },
    {
        name: "LLC Player Draft Event",
        image: "/player-draft.jpg",
        video: "https://www.linkedin.com/posts/madmak787_legends-league-cricket-draft-event-was-held-activity-7122134895526633472-RXPs"
    },
    {
        name: "DFab Cabin",
        image: "/dfabcabin.jpg",
        video: "http://www.dfabcabin.com/"
    },
    {
        featured: true,
        name: "Trophy Tour",
        image: "/trophy-tour.jpg",
        video: "/media/videos/trophy-tour.mp4"
    },
];