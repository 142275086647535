import { Info } from '@mui/icons-material'
import { IconButton, ImageListItem, ImageListItemBar } from '@mui/material'
import React from 'react'

const ImagePost = ({title,logo,image,course,university,period}) => {
    return (
        <>
            
            <ImageListItem key={title}>
                <img
                    srcSet={`/media/education${image}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    src={`/media/education${image}?w=248&fit=crop&auto=format`}
                    alt={title}
                    loading="lazy"
                />
                <ImageListItemBar
                    title={title}
                    subtitle={course}
                    actionIcon={
                        <IconButton
                            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                            aria-label={`info about ${course}`}
                        >
                            <Info />
                        </IconButton>
                    }
                />
            </ImageListItem>
        </>
    )
}

export default ImagePost