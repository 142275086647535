import Sidebar from "./components/Sidebar";
import Rightbar from "./components/Rightbar";
import Feed from "./components/Feed";
import { Box, Stack, createTheme } from "@mui/material";
import Navbar from "./components/Navbar";
import Add from "./components/Add";
import { useState } from "react";
import { ThemeProvider } from "@emotion/react";
import Alerts from "./components/Alerts";

function App() {
    const [open, setOpen] = useState(false);
	const [mode,setMode] = useState('light');

	const darkTheme = createTheme({
		palette:{
			mode: mode
		}
	})
	return (
		<ThemeProvider theme={darkTheme}>
			<Box bgcolor={"background.default"} color={"text.primary"}>
				<Navbar />
				<Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent={`space-between`}>
					<Sidebar mode={mode} setMode={setMode} open={open} setOpen={setOpen} />
					<Feed />
					<Rightbar />
				</Stack>
				<Add open={open} setOpen={setOpen} />
				<Alerts />
			</Box>
		</ThemeProvider>
	);
}

export default App;
