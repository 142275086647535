import { Box, Modal } from '@mui/material';
import React, { useRef, useState } from 'react'
import '../assets/css/video.css';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    maxWidth: '100%',
};

const Video = ({ open, setOpen, videosrc }) => {
    const [range,setRange] = useState({
        'volume':0,
        'playbackRate':1
    });

    let player = useRef();
    let video = useRef();
    let progress = useRef();
    let progressBar = useRef();
    let toggle = useRef();
    let skipButtons = useRef();
    let ranges = useRef();
    let fullscreen = useRef();

    let mousedown = false;

    function togglePlay() {
        (video.current.paused)?video.current.play():video.current.pause();
        // const method = video.current.paused?'play':'pause';
        // video.current[method]();
    }
    
    function updateButton(e) {
        const icon = video.current.paused?'►' : '❚ ❚';
        toggle.current.textContent = icon;
    }
    
    function skip(e) {
        let ths = e.currentTarget;
        video.current.currentTime += parseFloat(ths.dataset.skip);
    }
    
    function handleRangeUpdate(e) {
        const ths = e.target;
        const k = ths.name;
        video.current[k] = ths.value;
        setRange({...range,[k]:ths.value});
    }
    
    function handleProgress() {
        const percent = (video.current.currentTime / video.current.duration)*100;
        progressBar.current.style.flexBasis = `${percent}%`;
    }
    
    function scrub(e) {
        const scrubTime = (e.nativeEvent.offsetX / progress.current.offsetWidth) * video.current.duration;
        video.current.currentTime = scrubTime;
    }
    
    function toggleFullscreen() {
        if (document.fullscreenElement) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE11 */
                document.msExitFullscreen();
            }
        } else {
            if (player.current.requestFullscreen) {
                player.current.requestFullscreen();
            } else if (player.current.webkitRequestFullscreen) { /* Safari */
                player.current.webkitRequestFullscreen();
            } else if (player.current.msRequestFullscreen) { /* IE11 */
                player.current.msRequestFullscreen();
            }
        }
    }

    const handleClose = () => {
        setOpen(false)
        video.current.pause()
    }

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="player" ref={player}>
                        <video className="player__vid viewer" ref={video} src={videosrc} onClick={togglePlay} onPlay={updateButton} onPause={updateButton} onTimeUpdate={handleProgress} autoPlay></video>

                        <div className="player__controls">
                            <div className="progress" ref={progress} onClick={scrub} onMouseMove={(e) => mousedown && scrub(e)} onMouseDown={() => mousedown = true} onMouseUp={() => mousedown = false}>
                                <div className="progress__filled" ref={progressBar}></div>
                            </div>
                            <button className="player__button toggle" title="Toggle Play" ref={toggle} onClick={togglePlay}>►</button>
                            <input type="range" name="volume" className="player__slider" ref={ranges} onChange={handleRangeUpdate} onMouseMove={handleRangeUpdate} min="0" max="1" step="0.05" value={range.volume} />
                            <input type="range" name="playbackRate" className="player__slider" ref={ranges} onChange={handleRangeUpdate} onMouseMove={handleRangeUpdate} min="0.5" max="2" step="0.1" value={range.playbackRate} />
                            <button data-skip="-10" className="player__button" onClick={skip} ref={skipButtons}>« 10s</button>
                            <button data-skip="25" className="player__button" onClick={skip} ref={skipButtons}>25s »</button>
                            <button className="fullscreen__button" onClick={toggleFullscreen} ref={fullscreen}>&#x26F6;</button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default Video