import { CodeOff, Mail, Notifications } from '@mui/icons-material';
import { AppBar, Avatar, Badge, Box, InputBase, Menu, MenuItem, Toolbar, Typography, styled } from '@mui/material'
import { useEffect, useState } from 'react';
import WebFont from 'webfontloader';

const StyledToolbar = styled(Toolbar)({
    display: "flex",
    justifyContent: "space-between",
})

const Search = styled('div')(({ theme }) => ({
    backgroundColor: '#FFFFFF',
    padding: "0 10px",
    borderRadius: theme.shape.borderRadius,
    width: "40%"
}))

const Icons = styled(Box)(({ theme }) => ({
    display: "none",
    gap: "20px",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
        display: "flex"
    }
}))

const UserBox = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: "10px",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
        display: "none"
    }
}))

const Navbar = () => {
    const [open, setOpen] = useState(false);
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Syncopate:wght@700']
            }
        });
    }, []);

    return (
        <AppBar position='sticky'>
            <StyledToolbar>
                <Typography variant='h6' fontWeight={`700`} fontFamily={`'Syncopate', sans-serif`} sx={{ display: { xs: "none", sm: "block" } }}>madmak787</Typography>
                <CodeOff sx={{ display: { xs: "block", sm: "none" } }} />
                <Search><InputBase placeholder='Search...' sx={{
                            input: {
                                color: '#000',
                                "&::placeholder": {
                                    opacity: 0.3,
                                },
                            }
                        }} /></Search>
                <Icons>
                    <Badge badgeContent={4} color="error">
                        <Mail />
                    </Badge>
                    <Badge badgeContent={2} color="error">
                        <Notifications />
                    </Badge>
                    <Avatar sx={{ width: 30, height: 30 }} src='/media/amir.jpg' onClick={e => setOpen(true)} />
                </Icons>
                <UserBox onClick={e => setOpen(true)}>
                    <Avatar sx={{ width: 30, height: 30 }} src='/media/amir.jpg' />
                    <Typography variant='span'>Mohd Amir Khan</Typography>
                </UserBox>
            </StyledToolbar>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                open={open}
                onClose={e => setOpen(false)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{marginTop:'48px'}}
            >
                <MenuItem href='#experiences'>Experience</MenuItem>
                <MenuItem href="#education">Education</MenuItem>
                <MenuItem>Logout</MenuItem>
            </Menu>
        </AppBar>
    )
}

export default Navbar